import React from 'react';

import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import ButtonSx from 'components/button/ButtonSx';
import { pxToRem } from 'theme-loader/theme/typography';

function CommonToggleViewSection({
  heading,
  selectLabel,
  options,
  selectedOption,
  onSelectChange,
  debounceTime = 300,
  onSearch,
  rightActionBtns = [],
  onFileChange,
  csvRef,
  tabsSx,
  tabOptions = [],
  onTabChange,
  viewType,
  isDataLoading = false,
  extraOptions,
}) {
  return (
    <Box sx={{ height: '100%' }}>
      <Grid container mb={2.5} width="100%">
        <Grid item xs={12}>
          <Stack
            sx={{
              flexDirection: { sm: 'column', md: 'row' },
              alignItems: { sm: 'center' },
            }}
            justifyContent={'space-between'}
            gap={2}
          >
            <Stack
              direction="row"
              sx={{
                gap: 2,
                flexDirection: { xs: 'column', sm: 'row' },
              }}
              width={'100%'}
            >
              <Typography
                flexWrap={'wrap'}
                sx={{
                  fontSize: pxToRem(30),
                  fontWeight: 700,
                  mt: { xs: 2, sm: 0 },
                  mr: 1,
                }}
              >
                {heading}
              </Typography>
              <FormControl
                sx={{
                  minWidth: '30%',
                  width: { sm: '100%', md: '250px' },
                }}
              >
                <InputLabel id="demo-simple-select-label">
                  {selectLabel}
                </InputLabel>
                <Select
                  sx={{ borderRadius: 0 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedOption}
                  label="Selected Material"
                  onChange={onSelectChange}
                >
                  {options?.map((item) => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                onChange={onSearch}
                placeholder="Search"
                sx={{
                  width: '100%',
                  input: {
                    'borderRadius': '2px',
                    '&::placeholder': {
                      opacity: 1,
                    },
                  },
                }}
              />
            </Stack>
            <Stack
              direction="row"
              gap={2}
              sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
              width={'100%'}
              justifyContent={'end'}
            >
              {rightActionBtns?.map((action, index) => {
                return (
                  <ButtonSx
                    key={index}
                    startIcon={action?.startIcon}
                    buttonText={
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {action?.buttonText}
                      </Typography>
                    }
                    onButtonPress={action?.onPress}
                    isLoading={action?.isLoading || false}
                    sx={{
                      borderRadius: '5px',
                      height: 50,
                      ...action?.sx,
                    }}
                  />
                );
              })}
              {extraOptions?.map((extraOption, index) => (
                <React.Fragment key={index}>{extraOption}</React.Fragment>
              ))}
            </Stack>
            <Box sx={{ display: 'none' }}>
              <input
                type="file"
                accept=".csv"
                onChange={onFileChange}
                ref={csvRef}
              />
            </Box>{' '}
          </Stack>
        </Grid>
      </Grid>
      <Box width={'100%'}>
        <Tabs
          value={viewType}
          onChange={onTabChange}
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          sx={{
            'backgroundColor': '#E5E5E5',
            'mb': 2,
            'width': '100%',
            '& .MuiTabs-indicator': {
              display: 'none', // Hides the indicator
            },
            ...tabsSx,
          }}
        >
          {tabOptions?.map((tab, index) => {
            return (
              <Tab
                key={index}
                sx={{
                  '&.Mui-selected': {
                    fontWeight: 'bold',
                    backgroundColor: 'lightgrey',
                  },
                  ...tab.sx,
                }}
                value={tab?.value}
                label={tab?.label}
              />
            );
          })}
        </Tabs>
      </Box>

      <Grid container spacing={2} my={2}>
        {isDataLoading ? (
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="60vh"
          >
            <CircularProgress />
          </Box>
        ) : (
          tabOptions?.find((view) => {
            return view?.value === viewType;
          })?.component
        )}
      </Grid>
    </Box>
  );
}

CommonToggleViewSection.propTypes = {
  csvRef: PropTypes.any,
  debounceTime: PropTypes.number,
  heading: PropTypes.any,
  isDataLoading: PropTypes.bool,
  onFileChange: PropTypes.any,
  onSearch: PropTypes.any,
  onSelectChange: PropTypes.any,
  onTabChange: PropTypes.any,
  options: PropTypes.any,
  rightActionBtns: PropTypes.array,
  selectLabel: PropTypes.any,
  selectedOption: PropTypes.any,
  tabOptions: PropTypes.array,
  tabsSx: PropTypes.any,
  viewType: PropTypes.any,
  extraOptions: PropTypes.any,
};

export default CommonToggleViewSection;
