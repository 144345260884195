import React, { useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Tooltip,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import FileSaver from 'file-saver';
import debounce from 'lodash.debounce';
import moment from 'moment';
import './Orders.css';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import AlertSx from 'components/alert/AlertSx';
import ProjectPopup from 'components/project-popup/ProjectPopup';
import useAuth from 'context/AuthContext';
import {
  useGetCustomerQuery,
  useGetSearchedUsersQuery,
} from 'store/apis/customer';
import { pxToRem } from 'theme-loader/theme/typography';
import { b64toBlob, downloadPDF } from 'utils/commonFunctions';
import { useNotifyToast } from 'utils/useNotifyToast';

import OrderItemDialog from './components/OrderItemDialog';
import {
  useCreateDuplicateProjectsMutation,
  useGetAllMaterialsQuery,
  useGetAllProjectsOfUserQuery,
  useGetOrdersQuery,
  useLazyDownloadReviewOrderQuery,
  useLazyGetUploadedFileQuery,
} from '../../store/apis/order';

export const defaultMaterial = 'White Melamine';
const validationSchema = yup.object({
  projects: yup.array().of(
    yup.object({
      name: yup.string().required('Project name cannot be empty'),
    })
  ),
});

export const datagridSx = {
  '&.MuiDataGrid-root': {
    border: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: 14,
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-columnHeader': {
    borderRadius: 0,
  },
  '.MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '.MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
    outline: 'none !important',
  },
  '.MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },
  '&>.MuiDataGrid-main': {
    '& .MuiDataGrid-row--borderBottom': {
      borderBottom: 'none',
      backgroundColor: '#E5E5E5',
    },

    '& .MuiDataGrid-cell': {
      borderTop: 'none',
      boxSizing: 'none',
      borderLeft: 'none',
    },
  },
};

export default function Orders() {
  const notifyToast = useNotifyToast();
  const [filter, setFilter] = React.useState({
    sortBy: 'createdAt',
    orderBy: 'desc',
    page: 1,
    limit: 10,
  });
  const [sortModel, setSortModel] = useState({});
  const [customerFilter, setCustomerFilter] = React.useState({
    page: 1,
    limit: 10,
  });
  const auth = useAuth();
  const { data: user } = useGetCustomerQuery(
    {},
    { skip: !auth?.authenticated }
  );

  const [
    getUploadedFile,
    { isFetching: isDesignDownloading, originalArgs: designOgArgs },
  ] = useLazyGetUploadedFileQuery();
  const [handleDownload, { isFetching: isFetchingPdf, originalArgs }] =
    useLazyDownloadReviewOrderQuery();
  const [value, setValue] = React.useState([]);
  const [error, setError] = useState({
    isError: false,
    errorMessage: '',
  });

  const { data: materials } = useGetAllMaterialsQuery();

  const { caseMaterials, doorMaterials } = materials || {};

  const [
    createDuplicateProjects,
    {
      data: duplicateProjectsData,
      isLoading: isDuplicatingProjectsLoading,
      isSuccess: isDuplicatingProjectsSuccess,
      isError: isDuplicatingProjectsError,
      error: addDuplicateProjectsError,
    },
  ] = useCreateDuplicateProjectsMutation();
  const {
    control,
    reset,
    setValue: setDefaultValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      projectsFields: [],
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const { fields, append } = useFieldArray({
    control,
    name: 'projectsFields',
  });

  useEffect(() => {
    if (sortModel && sortModel?.length) {
      const { field, sort } = sortModel[0];
      setFilter((prevState) => ({
        ...prevState,
        sortBy: field,
        orderBy: sort,
      }));
    }
  }, [sortModel]);

  useEffect(() => {
    if (!isDuplicatingProjectsLoading) {
      if (isDuplicatingProjectsSuccess) {
        notifyToast(
          duplicateProjectsData?.message,
          'projectsDuplicated',
          'success'
        );
      } else if (isDuplicatingProjectsError) {
        notifyToast(
          addDuplicateProjectsError?.data?.errorMessage,
          'projectsDuplicationFailed',
          'error'
        );
      }
    }
  }, [
    addDuplicateProjectsError?.data?.errorMessage,
    duplicateProjectsData?.message,
    isDuplicatingProjectsError,
    isDuplicatingProjectsLoading,
    isDuplicatingProjectsSuccess,
    notifyToast,
  ]);

  const {
    data: allCustomerData,

    isFetching: isCustomersFetching,
  } = useGetSearchedUsersQuery(customerFilter, {
    skip: user?.role !== 'ADMIN',
  });

  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const [isProjectPopupOpen, setProjectPopupOpen] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [currentOrderId, setCurrentOrderId] = useState(null);

  const [projectsWithInvalidMaterials, setProjectsWithInvalidMaterials] =
    useState([]);

  const handlePdf = useCallback(
    async (order) => {
      const response = await handleDownload({
        id: order?.id,
        orderPlaced: true,
      });
      if (response.data) {
        const parsedData = JSON.parse(response?.data);
        const blob = b64toBlob(parsedData?.stream);
        const blobUrl = URL.createObjectURL(blob);
        downloadPDF({ blobUrl, fileName: order?.purchaseOrderNo });
      } else {
        setError({
          isError: true,
          errorMessage: 'Something went wrong!',
        });
      }
    },
    [handleDownload]
  );

  const downloadDesignFile = async (name) => {
    const response = await getUploadedFile(name);
    if (response?.error?.data?.errorMessage) {
      setError({
        isError: true,
        errorMessage: response?.error?.data?.errorMessage,
      });
    } else {
      downloadPDF({ blobUrl: response?.data?.url, name });
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', sortable: false },
    {
      field: 'createdAt',
      headerName: 'Date',
      sortable: true,
      minWidth: 165,
      flex: 1,
      renderCell: (params) => {
        return moment(params.value).format('MMMM DD, YYYY');
      },
    },
    ...(user?.role === 'ADMIN'
      ? [
          {
            field: 'firstName',
            headerName: 'Name',
            minWidth: 150,
            flex: 1,
            renderCell: (params) => {
              return params.row.firstName + ' ' + params.row.lastName;
            },
          },
          { field: 'email', headerName: 'Email', minWidth: 280, flex: 1 },
          {
            field: 'phone',
            headerName: 'Phone',
            minWidth: 150,
            flex: 1,
            sortable: false,
          },
        ]
      : []),
    {
      field: 'orderStatus',
      headerName: 'Status',
      sortable: true,
      minWidth: 125,
      flex: 1,
    },
    {
      field: 'purchaseOrderNo',
      headerName: 'Order #',
      sortable: true,
      minWidth: 145,
      flex: 1,
    },
    {
      field: 'projectName',
      headerName: 'Projects',
      sortable: true,
      minWidth: 155,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack height={'100%'} justifyContent={'center'}>
            <Tooltip
              title={params?.row?.projects
                .map((item) => item.projectName)
                .join(', ')}
              placement="top"
              arrow
            >
              <Typography
                noWrap
                sx={{
                  maxWidth: '100px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {params?.row?.projects
                  .map((item) => item.projectName)
                  .join(', ')}
              </Typography>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: 'jobName',
      headerName: 'Job Name',
      sortable: true,
      minWidth: 155,
      flex: 1,
    },
    {
      field: 'customerPO',
      headerName: 'Purchase Order #',
      sortable: true,
      minWidth: 155,
      flex: 1,
    },
    {
      field: 'address',
      headerName: 'Delivery Address',
      sortable: false,
      minWidth: 245,
      flex: 1,
    },
    {
      field: 'note',
      headerName: 'Notes / Special requests',
      sortable: false,
      minWidth: 245,
      flex: 1,
    },
    {
      field: 'totalCost',
      headerName: 'Total Cost',
      sortable: true,
      minWidth: 140,
      flex: 1,
    },

    {
      field: 'Action',
      headerName: 'Action',
      headerAlign: 'center',
      sortable: false,
      minWidth: 140,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack
            container
            direction="row"
            justifyContent="space-around"
            spacing={2}
            alignItems="center"
            height={'100%'}
          >
            <Button
              sx={{
                fontSize: '14px',
              }}
              onClick={() => {
                setOpenDialog(true);
                setSelectedItem(params);
              }}
            >
              View Details
            </Button>
          </Stack>
        );
      },
    },
    {
      field: 'Download',
      headerName: 'Download',
      sortable: false,
      minWidth: user?.role === 'ADMIN' ? 260 : 200,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Stack
            container
            direction="row"
            justifyContent={'center'}
            alignItems="center"
            height={'100%'}
          >
            {user?.role === 'ADMIN' ? (
              <Button
                sx={{
                  fontSize: '14px',
                }}
                onClick={async () => {
                  fetch(
                    `${process.env.REACT_APP_BASE_URL}/download-order/${params?.row?.id}`,
                    {
                      method: 'GET',
                    }
                  )
                    .then((response) => {
                      const contentType = response.headers.get('Content-Type');

                      if (contentType?.includes('text/plain')) {
                        return response.text();
                      } else {
                        return response.blob();
                      }
                    })
                    .then((payload) => {
                      if (payload.type === 'application/zip') {
                        FileSaver.saveAs(
                          payload,
                          `${params?.row?.purchaseOrderNo}.zip`
                        );
                      } else {
                        const blob = b64toBlob(
                          window.btoa(payload),
                          'application/octet-stream'
                        );
                        FileSaver.saveAs(
                          blob,
                          `${params?.row?.purchaseOrderNo}.ord`
                        );
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                      alert('Error downloading orders');
                    });
                }}
              >
                ORD
              </Button>
            ) : null}
            <Button
              sx={{
                fontSize: '14px',
                minWidth: '88px',
              }}
              onClick={() => handlePdf(params?.row)}
            >
              {isFetchingPdf && originalArgs?.id === params?.row?.id ? (
                <CircularProgress thickness={2} size={20} />
              ) : (
                'Order Detail'
              )}
            </Button>
            {params?.row?.designFile ? (
              <Button
                sx={{
                  fontSize: '14px',
                }}
                onClick={() => downloadDesignFile(params?.row?.designFile)}
              >
                {isDesignDownloading &&
                designOgArgs === params?.row?.designFile ? (
                  <CircularProgress thickness={2} size={20} />
                ) : (
                  'Design'
                )}
              </Button>
            ) : null}
          </Stack>
        );
      },
    },
    ...(user?.role === 'ADMIN'
      ? [
          {
            field: 'viewInClickup',
            headerName: 'View in clickup',
            sortable: false,
            minWidth: 140,
            flex: 1,
            renderCell: (params) => {
              return params?.row?.taskId ? (
                <Button
                  sx={{
                    fontSize: '14px',
                  }}
                  onClick={() => {
                    if (params?.row?.taskId) {
                      window.open(
                        `https://app.clickup.com/t/${params?.row?.taskId}`,
                        '_blank'
                      );
                    }
                  }}
                >
                  View
                </Button>
              ) : null;
            },
          },
        ]
      : []),

    {
      field: 'duplicateProject',
      headerName: 'Duplicate Project',
      sortable: false,
      minWidth: 140,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack
            alignItems="center"
            justifyContent={'center'}
            width={'100%'}
            height={'100%'}
          >
            <IconButton
              onClick={() => {
                // check if material exist

                const materialIds = params?.row?.projects?.reduce(
                  (acc, arr) => {
                    const projectNo = arr.projectNo;

                    if (!acc[projectNo]) {
                      acc[projectNo] = { case: '', door: '' };
                    }

                    acc[projectNo].case = arr?.caseMaterialId;
                    acc[projectNo].door = arr?.doorMaterialId;

                    return acc;
                  },
                  {}
                );

                const doorMaterialIdList =
                  materials?.doorMaterials?.map((item) => item.id) || [];
                const caseMaterialIdList =
                  materials?.caseMaterials?.map((item) => item.id) || [];

                const validationResult = Object.entries(materialIds).reduce(
                  (result, [projectNo, materials]) => {
                    result[projectNo] = {
                      case: caseMaterialIdList.includes(materials.case),
                      door: doorMaterialIdList.includes(materials.door),
                    };
                    return result;
                  },
                  {}
                );

                const projectsWithInvalidMaterials = Object.entries(
                  validationResult
                )
                  .filter(
                    ([_, { case: isCaseValid, door: isDoorValid }]) =>
                      !isCaseValid || !isDoorValid
                  )
                  .map(([projectNo, materials]) => ({
                    projectNo,
                    ...materials,
                  }));

                if (projectsWithInvalidMaterials?.length) {
                  setProjectsWithInvalidMaterials(projectsWithInvalidMaterials);
                }
                setCurrentOrderId(params?.row?.id);
                append(
                  params?.row?.projects?.map((item) => ({
                    name: item?.projectName,
                    duplicatedFrom: item?.projectId,
                    orderProjectsId: item?.id,
                    selfDuplicationId: item?.duplicatedFrom,
                    projectNo: item.projectNo,
                    projectName: item.projectName,
                    cabinetCodes: item?.cabinetCodes,
                  }))
                );
                setProjectPopupOpen(true);
              }}
              color="inherit"
            >
              <ContentCopyIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const { data, isLoading, isFetching } = useGetOrdersQuery(
    { filter },
    { refetchOnMountOrArgChange: true }
  );
  const { data: allProjects } = useGetAllProjectsOfUserQuery(
    {},
    { skip: !user?.id || !auth?.authenticated }
  );

  const handleClose = () => {
    setOpenDialog(false);
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const debouncedChangeHandler = debounce(
    (e) =>
      setCustomerFilter((state) => ({
        ...state,
        search: e.target.value,
      })),
    300
  );

  const [selectedProjectsWithInvalidMat, setSelectedProjectsWithInvalidMat] =
    useState([]);
  const [formData, setFormData] = useState([]);
  const [matSelectionPage, setMatSelectionPage] = useState({ page: 0 });

  const handleCloseAlert = () => {
    if (matSelectionPage.page === 1) {
      setMatSelectionPage({ page: 0 });
    } else {
      setSelectedProjects([]);
      reset();
      setProjectPopupOpen(false);
      setProjectsWithInvalidMaterials([]);
      setSelectedProjectsWithInvalidMat([]);
      setFormData([]);
      setMatSelectionPage({ page: 0 });
    }
  };

  // code to handle the increment of duplicated projects

  const mergedObject = {};
  fields?.forEach((project) => {
    if (!mergedObject[project.name]) {
      mergedObject[project.name] = 1;
    }
    // note: here the "duplicatedFrom" id in fields is the id of the project only
    allProjects?.forEach((item) => {
      if (item?.duplicatedFrom === project?.duplicatedFrom) {
        mergedObject[project.name] = mergedObject[project.name] + 1;
      }
    });
  });

  const handleChange = (type, value, projectName) => {
    setFormData((prev) => {
      const existingIndex = prev.findIndex(
        (item) => item?.projectName === projectName
      );

      const updatedItem = {
        projectName,
      };

      if (existingIndex !== -1) {
        return prev.map((item, index) => {
          if (index === existingIndex) {
            if (type === 'Door') {
              return { ...item, doorType: 'Door', doorValue: value };
            }
            if (type === 'Case') {
              return { ...item, caseType: 'Case', caseValue: value };
            }
            return item;
          }
          return item;
        });
      } else {
        if (type === 'Door') {
          updatedItem.doorType = 'Door';
          updatedItem.doorValue = value;
        }
        if (type === 'Case') {
          updatedItem.caseType = 'Case';
          updatedItem.caseValue = value;
        }

        return [...prev, updatedItem];
      }
    });
  };

  const handleCheckSelection = () => {
    const initialData = [];

    selectedProjectsWithInvalidMat.forEach((p) => {
      const projectName = selectedProjects?.find(
        (project) => project?.projectNo === p?.projectNo
      )?.name;

      const existingProject = initialData.find(
        (item) => item.projectName === projectName
      );

      if (!existingProject) {
        const newProject = { projectName };

        if (!p.door) {
          newProject.doorType = 'Door';
          newProject.doorValue = defaultMaterial;
        }

        if (!p.case) {
          newProject.caseType = 'Case';
          newProject.caseValue = defaultMaterial;
        }

        initialData.push(newProject);
      } else {
        if (!p.door && !existingProject.doorType) {
          existingProject.doorType = 'Door';
          existingProject.doorValue = defaultMaterial;
        }

        if (!p.case && !existingProject.caseType) {
          existingProject.caseType = 'Case';
          existingProject.caseValue = defaultMaterial;
        }
      }
    });
    const newInitialData = initialData.map((initialItem) => {
      const matchingFormData = formData.find(
        (item) => item.projectName === initialItem.projectName
      );

      if (matchingFormData) {
        return {
          ...initialItem,
          ...matchingFormData,
        };
      }

      return initialItem;
    });

    setFormData(newInitialData);
  };

  return (
    <Box sx={{ height: '100%', mx: 2 }}>
      <Grid container pt={4} mb={3.5} width="100%">
        <Grid item xs={12} sm={6} lg={8}>
          <Typography
            sx={{
              fontSize: pxToRem(30),
              fontWeight: 700,
              ml: { xs: 1, md: 0 },
            }}
          >
            My orders
          </Typography>
        </Grid>
        <Grid item sm={6} xs={12} lg={4} sx={{ mt: { xs: 2, sm: 0 } }}>
          {user?.role === 'ADMIN' ? (
            <Autocomplete
              multiple
              limitTags={2}
              id="multiple-limit-tags"
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {`${option.firstName} ${option.lastName} (${option.email})`}
                  </li>
                );
              }}
              onChange={(event, newValue) => {
                if (value.length > 4 && newValue.length >= 5) {
                  notifyToast(
                    'Maximum 5 customers can be selected',
                    'maxCustomer',
                    'info'
                  );
                } else {
                  setValue(newValue?.map((option) => option.value || option));
                  const customers = newValue?.map((item) => item.id);
                  setFilter((prevState) => ({ ...prevState, customers }));
                }
              }}
              value={value}
              isOptionEqualToValue={(option, value) => {
                return option?.id === value?.id;
              }}
              options={allCustomerData?.data || []}
              loading={isCustomersFetching}
              loadingText={'Searching...'}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName} (${option.email})`
              }
              onSelect={debouncedChangeHandler}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search by customer"
                  placeholder="Search or select customer"
                />
              )}
            />
          ) : null}
        </Grid>
      </Grid>
      <Paper elevation={0} sx={{ mb: 8 }}>
        <DataGrid
          onSortModelChange={(model) => {
            setSortModel(model);
          }}
          sortingMode="server"
          sortingOrder={['desc', 'asc']}
          autoHeight
          disableColumnMenu
          sx={datagridSx}
          initialState={{
            sorting: {
              sortModel: [{ field: 'createdAt', sort: 'desc' }],
            },
            pagination: {
              page: 1,
              pageSize: 10,
            },
          }}
          rowCount={data?.counts || 0}
          pageSizeOptions={[10, 20, 30, 40, 50, 60, 70]}
          pagination
          paginationMode="server"
          paginationModel={{
            page: filter.page - 1,
            pageSize: filter.limit,
          }}
          onPaginationModelChange={(e) => {
            const { page, pageSize } = e;
            setFilter({ ...filter, page: page + 1, limit: pageSize });
          }}
          rows={
            data?.data?.map((item) => ({
              ...item,
              purchaseOrderNo: item.purchaseOrderNo,
              customerPO: item.customerPO,
              totalCost: Number(item.totalCost).toFixed(2),
              totalItem: Number(item.totalItem),
              discountAmount: Number(item.discountAmount),
            })) || []
          }
          columns={columns}
          loading={isLoading || isFetching}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Orders
              </Stack>
            ),
          }}
        />
      </Paper>
      {openDialog && (
        <OrderItemDialog
          open={openDialog}
          handleClose={handleClose}
          selectedItem={selectedItem}
        />
      )}
      <AlertSx
        message={error.errorMessage}
        severity="error"
        showAlert={!!error.isError}
        handleClose={() =>
          setError({
            isError: false,
            errorMessage: '',
          })
        }
        sx={{ top: { xs: 100 } }}
        autoHideDuration={2000}
      />
      {isProjectPopupOpen && (
        <ProjectPopup
          desc={
            matSelectionPage.page === 1
              ? 'Please choose materials for the projects listed below'
              : 'Please choose projects to duplicate from'
          }
          isOpen={isProjectPopupOpen}
          onClose={handleCloseAlert}
          title={'Duplicate projects'}
          cancelButtonText={!matSelectionPage.page ? 'Cancel' : 'Back'}
          submitButtonText={
            selectedProjectsWithInvalidMat?.length &&
            matSelectionPage.page === 0
              ? 'Next'
              : 'Duplicate'
          }
          paperStyles={{ minWidth: '600px' }}
          isConfirmDisabled={
            !selectedProjects?.length || !!Object.keys(errors)?.length
          }
          isConfirmLoading={isDuplicatingProjectsLoading}
          childComponent={
            <>
              <Divider sx={{ mb: 1 }} />
              {matSelectionPage.page === 1 ? (
                <form>
                  <Stack
                    sx={{
                      my: 2,
                      px: 1,
                      maxHeight: '300px',
                      overflowY: 'auto',
                    }}
                  >
                    {selectedProjectsWithInvalidMat?.map((p) => {
                      const projectName = selectedProjects?.find(
                        (project) => project?.projectNo === p?.projectNo
                      )?.name;

                      const selectedMatData =
                        formData?.find(
                          (item) => item.projectName === projectName
                        ) || {};

                      return (
                        <Stack key={p?.projectNo}>
                          <Typography variant="h6">{projectName}</Typography>
                          <Stack
                            direction={'row'}
                            gap={3}
                            sx={{
                              flexDirection: { xs: 'column', lg: 'row' },
                              my: 3,
                            }}
                          >
                            {!p?.case && (
                              <TextField
                                select
                                label={'Case Material'}
                                fullWidth
                                value={
                                  selectedMatData?.caseValue || defaultMaterial
                                }
                                onChange={(e) =>
                                  handleChange(
                                    'Case',
                                    e.target.value,
                                    projectName
                                  )
                                }
                              >
                                {caseMaterials?.map((item) => (
                                  <MenuItem value={item.name} key={item.id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}

                            {!p?.door && (
                              <TextField
                                select
                                label={'Door Material'}
                                fullWidth
                                value={
                                  selectedMatData?.doorValue || defaultMaterial
                                }
                                onChange={(e) =>
                                  handleChange(
                                    'Door',
                                    e.target.value,
                                    projectName
                                  )
                                }
                              >
                                {doorMaterials?.map((item) => (
                                  <MenuItem value={item.name} key={item.id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Stack>
                </form>
              ) : (
                <>
                  <form>
                    <Stack
                      gap={2}
                      sx={{
                        overflowY: 'auto',
                        height: 'auto',
                        maxHeight: '300px',
                        my: 3,
                      }}
                    >
                      {fields?.map((project, index) => {
                        const hasMissingCab = project?.cabinetCodes?.filter(
                          (code) => !code.exists
                        );
                        return (
                          <Stack
                            key={project?.duplicatedFrom}
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            gap={2}
                          >
                            {selectedProjects?.some(
                              (item) =>
                                item?.duplicatedFrom === project?.duplicatedFrom
                            ) ? (
                              <Controller
                                key={project?.duplicatedFrom}
                                control={control}
                                name={`projects.${index}.name`}
                                defaultValue={`${project?.name}-copy-${
                                  mergedObject[project?.name]
                                }`}
                                render={({ field }) => {
                                  const { onChange } = field;
                                  return (
                                    <>
                                      <TextField
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              {!!hasMissingCab?.length && (
                                                <Tooltip
                                                  title={`Cabinet(s) ${hasMissingCab
                                                    ?.map((item) => item.name)
                                                    .join(
                                                      ','
                                                    )} no longer exists. Make sure to review the duplicated projects before ordering.`}
                                                  arrow
                                                >
                                                  <InfoOutlinedIcon
                                                    sx={{
                                                      color: 'blue',
                                                    }}
                                                  />
                                                </Tooltip>
                                              )}
                                            </InputAdornment>
                                          ),
                                        }}
                                        {...field}
                                        onChange={(e) => {
                                          const newValue = e.target.value;

                                          setSelectedProjects((prev) =>
                                            prev.map((item) =>
                                              item?.duplicatedFrom ===
                                              project?.duplicatedFrom
                                                ? { ...item, name: newValue }
                                                : item
                                            )
                                          );
                                          onChange(e);
                                        }}
                                        key={project?.duplicatedFrom}
                                        sx={{ width: '100%' }}
                                        error={!!errors.projects?.[index]?.name}
                                        helperText={
                                          errors.projects?.[index]?.name
                                            ?.message
                                        }
                                        inputProps={{ maxLength: 70 }}
                                      />
                                    </>
                                  );
                                }}
                              />
                            ) : (
                              <Typography sx={{ p: 1.8 }}>
                                {project?.name}
                              </Typography>
                            )}
                            <Checkbox
                              id={project?.duplicatedFrom}
                              checked={selectedProjects?.some(
                                (item) =>
                                  item?.duplicatedFrom ===
                                  project?.duplicatedFrom
                              )}
                              onChange={(e) => {
                                const { checked } = e.target;
                                if (!checked) {
                                  clearErrors(`projects.${index}.name`);
                                  setDefaultValue(
                                    `projects.${index}.name`,
                                    `${project?.name}-copy-${
                                      mergedObject[project?.name]
                                    }`
                                  );
                                }

                                handleCheckSelection();

                                if (projectsWithInvalidMaterials?.length) {
                                  const selectedProject =
                                    projectsWithInvalidMaterials?.find(
                                      (item) =>
                                        item?.projectNo === project?.projectNo
                                    );

                                  if (
                                    !selectedProjectsWithInvalidMat
                                      ?.map((sp) => sp.projectNo)
                                      .includes(selectedProject?.projectNo) &&
                                    checked &&
                                    selectedProject
                                  ) {
                                    setSelectedProjectsWithInvalidMat(
                                      (prev) => [
                                        ...prev,
                                        {
                                          ...selectedProject,
                                          projectNo: selectedProject?.projectNo,
                                          projectName: project?.projectName,
                                        },
                                      ]
                                    );
                                  } else {
                                    setSelectedProjectsWithInvalidMat(
                                      (prev) => {
                                        return prev.filter(
                                          (pI) =>
                                            pI.projectNo !==
                                            selectedProject?.projectNo
                                        );
                                      }
                                    );
                                  }
                                }
                                setSelectedProjects((prev) =>
                                  checked
                                    ? [
                                        ...prev,
                                        {
                                          duplicatedFrom:
                                            project?.duplicatedFrom,
                                          name: `${project?.name}-copy-${
                                            mergedObject[project?.name]
                                          }`,
                                          orderProjectsId:
                                            project?.orderProjectsId,
                                          projectNo: project?.projectNo,
                                        },
                                      ]
                                    : prev.filter(
                                        (item) =>
                                          item?.duplicatedFrom !==
                                          project?.duplicatedFrom
                                      )
                                );
                              }}
                            />
                          </Stack>
                        );
                      })}
                    </Stack>
                  </form>
                  {!!selectedProjects?.length && (
                    <Typography sx={{ p: 1 }}>
                      {selectedProjectsWithInvalidMat?.length &&
                      matSelectionPage.page !== 1
                        ? 'Warning: The door/case material of the selected project(s) no longer exists. Please click Next to select a different material.'
                        : 'Note: Make sure to verify the name of the duplicated project'}
                    </Typography>
                  )}
                </>
              )}
            </>
          }
          onConfirm={() => {
            if (
              matSelectionPage.page === 0 &&
              selectedProjectsWithInvalidMat?.length
            ) {
              setMatSelectionPage({ page: 1 });
              handleCheckSelection();
            } else {
              createDuplicateProjects({
                data: selectedProjects?.map(({ projectNo, ...rest }) => {
                  const materialData =
                    formData.find((item) => item.projectName === rest.name) ||
                    {};

                  return {
                    ...rest,
                    orderInfoId: currentOrderId,
                    validMatData: materialData,
                  };
                }),
              });

              setSelectedProjects([]);
              reset();
              setProjectPopupOpen(false);
              setProjectsWithInvalidMaterials([]);
              setSelectedProjectsWithInvalidMat([]);
              setFormData([]);
              setMatSelectionPage({ page: 0 });
            }
          }}
        />
      )}
    </Box>
  );
}
