import React, { useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { RichTreeView, useTreeViewApiRef } from '@mui/x-tree-view';
import PropTypes from 'prop-types';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import placeHolderImage from 'assets/img/placeholder.jpg';
import AccordionCustom from 'components/accordian/AccordionCustom';
import { useGetAllMaterialsQuery } from 'store/apis/order';
import { pxToRem } from 'theme-loader/theme/typography';
import { queryParamsBuilder, sortNestedData } from 'utils/commonFunctions';
import { FILTERS } from 'utils/constants';

function CommonFilterTreeView({
  onCheck = () => {},
  setSelectedItems,
  selectedItems,
  matType,
}) {
  const toggledItemRef = React.useRef({});
  const apiRef = useTreeViewApiRef();

  const updatedFilters = matType !== 'door' ? FILTERS.slice(1) : FILTERS;

  const [isExpanded, setIsExpanded] = useState(() => {
    return updatedFilters?.length > 0
      ? { [updatedFilters[0]?.title]: true }
      : {};
  });

  const handleChangeExpanded = (panel) => () => {
    setIsExpanded((prevState) => {
      return {
        ...prevState,
        [panel]: !prevState[panel],
      };
    });
  };

  const handleItemSelectionToggle = (event, itemId, isSelected) => {
    toggledItemRef.current[itemId] = isSelected;
  };

  const handleSelectedItemsChange = (event, newSelectedItems) => {
    onCheck(newSelectedItems);
    setSelectedItems(newSelectedItems?.map((item) => item.id));
  };

  return (
    <>
      {updatedFilters?.map((filter, index) => {
        return (
          <Box
            key={index}
            sx={{
              'minWidth': 290,
              '& .Mui-selected': {
                backgroundColor: 'transparent !important', // Removes the background
              },
            }}
          >
            <AccordionCustom
              title={
                <Typography fontSize={pxToRem(19)} fontWeight={500}>
                  {filter?.title}
                </Typography>
              }
              expanded={!!isExpanded[filter?.title]}
              handleChange={handleChangeExpanded(filter?.title)}
              sx={{
                '& .MuiAccordionSummary-root': {
                  background: 'white',
                },
                '& .MuiCollapse-root': {
                  background: 'white',
                },
                '& .MuiBox-root': {
                  minHeight: '0px',
                },
              }}
            >
              <RichTreeView
                multiSelect
                checkboxSelection
                // slotProps={{
                //   endIcon: (item) => ({
                //     item: item, // Pass the specific item to the endIcon slot
                //   }),
                // }}
                apiRef={apiRef}
                // slots={{
                //   ...(filter.title.includes('Colors') && {
                //     endIcon: ({ item }) => <RoundCircle filter={filter} />,
                //   }),
                // }}
                items={filter?.children}
                selectedItems={selectedItems}
                onSelectedItemsChange={(event, selectedIds) => {
                  const selectedLabels = selectedIds
                    .map((id) => {
                      const matchedItem = FILTERS?.flatMap(
                        (item) => item.children || []
                      ).find((item) => item.id === id);
                      return matchedItem ? matchedItem : null;
                    })
                    .filter(Boolean);

                  handleSelectedItemsChange(event, selectedLabels);
                }}
                onItemSelectionToggle={handleItemSelectionToggle}
                sx={{
                  '.MuiTreeItem-content': {
                    'display': 'flex',
                    'alignItems': 'flex-start',
                    '.MuiTreeItem-iconContainer': {
                      display: 'none',
                    },
                    'px': 0,
                    // ...(filter.title === 'Colors and Textures' && {
                    //   '.MuiSvgIcon-root': {
                    //     display: 'none',
                    //   },
                    // }),
                  },
                  // ...(filter.title === 'Colors and Textures' && {
                  //   '.MuiCheckbox-root': (theme) => ({
                  //     'borderRadius': '50%', // Make the checkbox round
                  //     'border': '2px solid', // Add a border
                  //     'borderColor': 'gray', // Conditional border color
                  //     'backgroundColor': 'transparent', // Conditional background
                  //     'width': '26px',
                  //     'height': '24px',
                  //     '&:hover': {
                  //       backgroundColor: 'rgba(0, 0, 0, 0.08)', // Hover effect
                  //     },
                  //   }),
                  // }),
                }}
              />
            </AccordionCustom>
            <Divider
              sx={{ borderBottomWidth: '1px', backgroundColor: '#000000' }}
            />
          </Box>
        );
      })}
    </>
  );
}

CommonFilterTreeView.propTypes = {
  handleClearAll: PropTypes.any,
  matType: PropTypes.string,
  onCheck: PropTypes.func,
  selectedItems: PropTypes.any,
  setSelectedItems: PropTypes.func,
};

const handleFilterData = (chipData, matData) => {
  const regex = new RegExp(chipData?.map((item) => item.label).join('|'), 'i');

  let filteredChildren = [];
  const filteredData = matData
    .map((item) => {
      if (item.children) {
        filteredChildren = item.children.filter(
          (child) =>
            regex.test(child.label) ||
            regex.test(child.id) ||
            regex.test(child.desc) ||
            regex.test(item.id) ||
            regex.test(item.label)
        );
      }

      if (
        regex.test(item.id) ||
        regex.test(item.label) ||
        regex.test(item.desc) ||
        filteredChildren.length > 0
      ) {
        return { ...item, children: filteredChildren };
      }

      return null;
    })
    .filter(Boolean);

  return filteredData;
};

const SelectMaterial = () => {
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from');
  const location = useLocation();

  const navigate = useNavigate();
  const { materialType } = useParams();
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [matData, setMatData] = React.useState([]);
  const [filteredMat, setFilteredMat] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [chipData, setChipData] = React.useState([]);
  const [sortOrder, setSortOrder] = React.useState('a-z');

  const [viewSize, setViewSize] = React.useState({
    type: 'large',
    size: {
      xs: 12,
      sm: 3,
      md: 3,
    },
  });

  const { data, isLoading, isFetching } = useGetAllMaterialsQuery();

  const handleDelete = (chipToDelete) => () => {
    const filteredChipData = chipData.filter(
      (chip) => chip.key !== chipToDelete.key
    );

    if (!filteredChipData.length) {
      let sortedData = matData;
      if (sortOrder) {
        sortedData = sortNestedData(sortedData, sortOrder);
      }
      setFilteredMat(sortedData);
    } else {
      // code to  filter as per the Filters
      const regex = new RegExp(
        filteredChipData?.map((item) => item.label).join('|'),
        'i'
      );

      const filteredData = matData
        .map((item) => {
          const filteredChildren = item.children.filter(
            (child) => regex.test(child.label) || regex.test(child.id)
          );

          if (
            regex.test(item.id) ||
            regex.test(item.label) ||
            filteredChildren.length > 0
          ) {
            return { ...item, children: filteredChildren };
          }

          return null;
        })
        .filter(Boolean);
      setFilteredMat(filteredData);
    }
    setChipData(filteredChipData);
    setSelectedItems((prevItems) =>
      prevItems.filter((item) => item !== chipToDelete.key)
    );
  };

  const handleFilterMatSearch = (e) => {
    const searchText = e.target.value.trim();
    setSearchText(searchText);

    if (!searchText) {
      let updatedFilterData = matData;
      if (chipData?.length) {
        updatedFilterData = handleFilterData(chipData, matData);
      }

      if (sortOrder) {
        updatedFilterData = sortNestedData(updatedFilterData, sortOrder);
      }

      setFilteredMat(updatedFilterData);

      return;
    }

    const regex = new RegExp(searchText, 'i');
    const searchResult = filteredMat?.reduce((acc, item) => {
      const obj = { ...item };

      if (item?.children) {
        obj.children = item.children.filter((subItem) =>
          regex.test(subItem?.label)
        );
        if (obj.children.length > 0) {
          acc.push(obj);
        }
      } else if (regex.test(item?.label)) {
        acc.push(obj);
      }

      return acc;
    }, []);

    setFilteredMat(searchResult);
  };

  React.useEffect(() => {
    if (data && !isLoading && !isFetching) {
      const caseType = materialType?.split(' ')?.[0];
      const materialsKey = `${caseType?.toLowerCase()}Materials`;

      if (caseType === 'Door') {
        const groupedData = data?.[materialsKey]?.reduce((acc, item) => {
          let parentName = 'Misc Doors';
          if (/Melamine\b/.test(item.name)) {
            parentName = 'Melamine/TFL Doors';
          } else if (/RTF\b/.test(item.name)) {
            parentName = 'Thermofoil/RTF Doors';
          } else if (/Standard\b/.test(item.name)) {
            parentName = 'Painted or Stained Wood Doors - Standard Colors';
          } else if (/Custom\b/.test(item.name)) {
            parentName = 'Painted or Stained Wood Doors - Custom Colors';
          }

          const existingGroup = acc.find((group) => group.label === parentName);
          if (existingGroup) {
            existingGroup.children.push({
              id: item.id,
              label: item.name,
              img: item.img,
            });
          } else {
            acc.push({
              id: parentName,
              label: parentName,
              children: [
                {
                  id: item.id,
                  label: item.name,
                  img: item.img,
                },
              ],
            });
          }

          return acc;
        }, []);

        setMatData(groupedData);
        setFilteredMat(groupedData);
      } else {
        const arr = data?.[materialsKey]?.map((item) => ({
          id: item.id,
          label: item.name,
          img: item.img,
        }));
        setMatData(arr);
        setFilteredMat(arr);
      }
    }
  }, [data, isFetching, isLoading, materialType]);
  return (
    <Stack
      alignItems={'center'}
      sx={{
        height: { xs: '100%' },
        overflow: 'hidden',
      }}
    >
      <Stack
        gap={4}
        sx={{
          flexDirection: { xs: 'column', lg: 'row' },
          width: '100%',
          height: '100%',
        }}
      >
        <Stack
          gap={2}
          sx={{
            width: '100%',
            flex: 1,
            height: '100%',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              bgcolor: 'white',
              p: 3,
              py: 1,
              height: 'calc(100% - 50px)',
              top: 0, // Sticks to the top
              overflow: 'hidden',
              width: '100%',
            }}
          >
            <Stack
              sx={{
                backgroundColor: '#000000',
                my: 2,
                p: 1,
                width: '100%',
              }}
            >
              <Typography
                sx={{
                  fontSize: 20,
                  color: 'white',
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                FILTERS
              </Typography>
            </Stack>
            <CommonFilterTreeView
              matType={location.state}
              setFilteredMat={setFilteredMat}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              onCheck={(selectedData) => {
                if (!selectedData.length) {
                  setFilteredMat(matData);
                } else {
                  const filteredData = handleFilterData(selectedData, matData);
                  setFilteredMat(filteredData);
                }

                setChipData((prev) => {
                  const updatedChipData = prev.filter((chip) =>
                    selectedData?.map((item) => item.id).includes(chip.key)
                  );

                  selectedData.forEach((item) => {
                    if (!updatedChipData.some((chip) => chip.key === item.id)) {
                      updatedChipData.push({
                        key: item.id,
                        label: item.label,
                      });
                    }
                  });

                  if (sortOrder) {
                    return sortNestedData(updatedChipData, sortOrder);
                  }

                  return updatedChipData;
                });
              }}
            />
          </Box>
        </Stack>
        <Stack
          gap={2}
          sx={{
            flex: 3,
            height: '100%',
          }}
        >
          <Typography
            sx={{ textAlign: 'center', fontWeight: 600, fontSize: 36, mb: 2 }}
          >
            {materialType}
          </Typography>

          <Stack
            direction={{ xs: 'column', md: 'row' }}
            sx={{
              pl: 2,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            gap={1}
          >
            <Stack direction={'row'}>
              <Typography sx={{ fontWeight: 700 }}>
                &nbsp;
                {materialType !== 'Door Material'
                  ? filteredMat?.length
                  : filteredMat?.reduce((acc, mat) => {
                      acc += mat.children?.length;
                      return acc;
                    }, 0)}
                &nbsp;
              </Typography>
              Materials
            </Stack>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              alignItems={{ xs: 'stretch', md: 'center' }}
              gap={1}
              sx={{ width: { xs: '100%', md: 'inherit' }, p: { xs: 1, sm: 0 } }}
            >
              <FormControl
                sx={{
                  minWidth: '30%',
                  width: { xs: '100%', md: '250px' },
                }}
              >
                <InputLabel id="demo-simple-select-label">Sort</InputLabel>
                <Select
                  sx={{ borderRadius: 0 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sortOrder}
                  label="Sort"
                  onChange={(e) => {
                    const sortedData = sortNestedData(
                      filteredMat,
                      e.target.value
                    );
                    setSortOrder(e.target.value);
                    setFilteredMat(sortedData);
                  }}
                >
                  {[
                    { id: 'a-z', label: 'Material Name (A-Z)' },
                    { id: 'z-a', label: 'Material Name (Z-A)' },
                  ]?.map((item) => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  minWidth: '30%',
                  width: { xs: '100%', md: '250px' },
                }}
              >
                <InputLabel id="demo-simple-select-label">View</InputLabel>
                <Select
                  sx={{ borderRadius: 0 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={viewSize?.type === 'large' ? 'large' : 'small'}
                  label="View"
                  onChange={(e) => {
                    if (viewSize?.type === 'large') {
                      setViewSize({
                        type: 'small',
                        size: { xs: 6, sm: 3, md: 2 },
                      });
                    } else {
                      setViewSize({
                        type: 'large',
                        size: { xs: 12, sm: 4, md: 3 },
                      });
                    }
                  }}
                >
                  {[
                    { id: 'large', label: 'Large Thumbnails' },
                    { id: 'small', label: 'Small Thumbnails' },
                  ]?.map((item) => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                onChange={handleFilterMatSearch}
                value={searchText}
                placeholder="Search material name"
                id="outlined-basic"
                variant="outlined"
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor: 'white',
                    maxWidth: { xs: '100%', md: '100%' },
                    width: '100%',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Stack>
          <Divider />

          {chipData && (
            <Stack
              direction="row"
              sx={{
                pl: 2,
                gap: 2,
                mb: 2,
                flexWrap: 'wrap',
                alignItems: 'center',
              }}
            >
              {chipData?.map((cData, index) => (
                <Chip
                  key={index}
                  label={cData.label}
                  onDelete={handleDelete(cData)}
                  sx={{
                    maxWidth: '150px',
                  }}
                />
              ))}
              {!!chipData?.length && (
                <Typography
                  sx={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: 'red',
                  }}
                  onClick={() => {
                    setSelectedItems([]);
                    setChipData([]);
                    setFilteredMat(matData);
                  }}
                >
                  Clear all
                </Typography>
              )}
            </Stack>
          )}

          <Grid
            container
            spacing={2}
            sx={{
              ...(materialType === 'Door Material' && { ml: 1 }),
              'px': 3,
              'overflowY': 'auto',
              '&::-webkit-scrollbar': {
                width: 0,
                background: 'transparent',
              },
              'msOverflowStyle': 'none',
              'scrollbarWidth': 'none',
              'height': '100vh',
            }}
          >
            {isLoading && (
              <Box
                py={8}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                width={'100%'}
              >
                <CircularProgress size={25} />
              </Box>
            )}
            {!isLoading && filteredMat && !filteredMat?.length && (
              <Stack
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Typography>No materials found</Typography>
              </Stack>
            )}

            {filteredMat?.map((mat, index) => {
              if (mat?.children?.length) {
                return (
                  <Box key={mat?.id} sx={{ width: '100%', mb: 2 }}>
                    {index !== 0 && <Divider sx={{ width: '100%', my: 2 }} />}
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        mb: 2,
                        fontSize: '20px',
                      }}
                    >
                      {mat?.label}
                    </Typography>
                    <Grid container spacing={1.5}>
                      {mat?.children?.map((item) => (
                        <Grid
                          key={item?.id}
                          item
                          {...viewSize?.size}
                          sx={{
                            p: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            onClick={() => {
                              navigate(
                                `/select-material/${materialType}/${item?.id}${
                                  from ? queryParamsBuilder({ from }) : ''
                                }`
                              );
                            }}
                            src={placeHolderImage}
                            alt="logo"
                            width={'100%'}
                            style={{
                              height: 'auto',
                              objectFit: 'contain',
                              border: '1px solid #EEEEEE',
                              cursor: 'pointer',
                            }}
                          />
                          <Tooltip title={item?.label} placement="top" arrow>
                            <Typography
                              noWrap
                              sx={{
                                maxWidth: '200px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                fontSize: { xs: '13px', md: '16px' },
                                mt: 1,
                              }}
                            >
                              {item?.label}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                );
              }
              return (
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: { xs: 'center', md: 'inherit' },
                    mb: 2,
                  }}
                  key={mat?.id}
                  item
                  {...viewSize?.size}
                >
                  <Box
                    sx={{
                      width: '100%',
                      maxWidth: '250px',
                      height: 'auto',
                      aspectRatio: '4 / 3',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      onClick={() => {
                        navigate(
                          `/select-material/${materialType}/${mat?.id}${
                            from ? queryParamsBuilder({ from }) : ''
                          }`
                        );
                      }}
                      src={mat?.img || placeHolderImage}
                      alt="logo"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        border: '1px solid #EEEEEE',
                        cursor: 'pointer',
                      }}
                    />
                  </Box>
                  <Tooltip title={mat?.label} placement="top" arrow>
                    <Typography
                      noWrap
                      sx={{
                        maxWidth: '180px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        fontSize: { xs: '13px', md: '16px' },
                        mt: 1,
                      }}
                    >
                      {mat?.label}
                    </Typography>
                  </Tooltip>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SelectMaterial;
