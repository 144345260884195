import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import placeHolderImage from 'assets/img/placeholder.jpg';
import ButtonSx from 'components/button/ButtonSx';
import ToolTipSx from 'components/tooltip/Tooltip';
function CommonGridView({
  data,
  noDataMsg,
  type,
  onItemClick = () => {},
  onDelete = () => {},
  onRestore = () => {},
  buttonText = 'Edit Details',
  onButtonPress = () => {},
  isBtnLoading = false,
  isBtnDisabled = false,
  selectedRestoreCabId = '',
}) {
  if (!data?.length) {
    return (
      <Stack
        width={'100%'}
        my={15}
        minHeight={150}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Typography>{noDataMsg}</Typography>
      </Stack>
    );
  }

  return data?.map((item, index) => {
    return (
      <Grid key={index} item xs={12} sm={6} md={3} lg={2} mb={2}>
        <Box
          onClick={() => onItemClick(item)}
          sx={{
            'position': 'relative',
            'width': '100%',
            ...(!item?.isDeleted && {
              cursor: 'pointer',
            }),
            '&:hover': {
              '.delete-icon, .restore-icon': {
                opacity: 1,
              },
            },
          }}
        >
          <Box
            component="img"
            src={
              (!item?.img ? placeHolderImage : item?.img) || placeHolderImage
            }
            alt={`${item?.name} Image`}
            sx={{
              width: '100%',
              height: {
                xs: '150px',
                sm: '200px',
                md: '220px',
                lg: '250px',
              },
              minHeight: '250px',
              objectFit: 'contain',
              position: 'relative',
            }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
            }}
          />
          {item?.index !== 1 && (
            <>
              {item?.isDeleted ? (
                <Tooltip title={`Restore ${type}`}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onRestore(item);
                    }}
                    className="restore-icon"
                    sx={{
                      position: 'absolute',
                      top: 5,
                      right: 5,
                      zIndex: 1,
                      opacity: 0,
                      transition: 'opacity 0.3s ease',
                    }}
                  >
                    <RestoreIcon
                      sx={{
                        color: (theme) => theme.palette.info.dark,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={`Delete ${type}`}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete(item);
                    }}
                    className="delete-icon"
                    sx={{
                      position: 'absolute',
                      top: 5,
                      right: 5,
                      zIndex: 1,
                      opacity: 0,
                      transition: 'opacity 0.3s ease',
                    }}
                  >
                    <DeleteIcon
                      sx={{
                        color: (theme) => theme.palette.error.dark,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </Box>

        {item?.name && (
          <ToolTipSx title={item?.name}>
            <Typography
              noWrap
              variant="subtitle1"
              fontWeight={500}
              pt={2}
              sx={{ textAlign: 'center' }}
            >
              {item?.name}
            </Typography>
          </ToolTipSx>
        )}
        {item?.shortName && (
          <ToolTipSx title={item?.shortName}>
            <Typography
              noWrap
              variant="subtitle1"
              fontWeight={500}
              pt={2}
              sx={{ textAlign: 'center' }}
            >
              {item?.shortName}
            </Typography>
          </ToolTipSx>
        )}

        {item?.longName && (
          <ToolTipSx title={item?.longName}>
            <Typography
              variant="body2"
              fontWeight={300}
              py={0.5}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                minHeight: 48,
                textAlign: 'center',
                heigh: '100%',
                fontSize: '15px',
              }}
            >
              {item?.longName}
            </Typography>
          </ToolTipSx>
        )}
        <Stack
          justifyContent={'center'}
          direction={'row'}
          gap={2}
          sx={{ mt: 2 }}
        >
          <ButtonSx
            isLoading={selectedRestoreCabId === item?.id && isBtnLoading}
            disabled={item?.isDeleted}
            sx={{
              px: 4,
              fontWeight: 700,
              width: '70%',
              mt: 1,
              mb: 2,
              fontSize: { xs: '14px' },
            }}
            buttonText={buttonText}
            onButtonPress={() => {
              onButtonPress(item);
            }}
          />
        </Stack>
      </Grid>
    );
  });
}

CommonGridView.propTypes = {
  buttonText: PropTypes.string,
  data: PropTypes.any,
  isBtnDisabled: PropTypes.bool,
  isBtnDisables: PropTypes.bool,
  isBtnLoading: PropTypes.bool,
  noDataMsg: PropTypes.any,
  onButtonPress: PropTypes.func,
  onDelete: PropTypes.func,
  onItemClick: PropTypes.func,
  onRestore: PropTypes.func,
  selectedRestoreCabId: PropTypes.string,
  type: PropTypes.any,
};

export default CommonGridView;
