import { useState, useEffect } from 'react';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import UsFlag from '../../../assets/img/us-flag.png';

const formatPhoneNumber = (number, isVisible) => {
  // if (!isVisible) {
  //   const length = number.length;
  //   return !length
  //     ? ''
  //     : `${'X'.repeat(Math.min(length, 3))}${
  //         length > 3
  //           ? `-${'X'.repeat(Math.min(Math.max(length - 3, 0), 3))}`
  //           : ''
  //       }${length > 6 ? `-${'X'.repeat(length - 6)}` : ''}`;
  // }

  if (number.length <= 3) {
    return number.replace(/(\d{0,3})/, '$1');
  } else if (number.length <= 6) {
    return `${number.slice(0, 3)}-${number.slice(3)}`;
  } else {
    return `${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(6, 10)}`;
  }
};

const CustomTextField = styled(TextField)`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    display: none;
  }
  input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
  }

  & input::-ms-reveal {
    display: none;
  }
`;

export default function HookTextField(props) {
  const { field, fieldState, formState } = useController(props);
  const {
    type,
    label,
    isPhone,
    isNotRequired,
    secondaryLabel,
    onBlur,
    sx,
    ...rest
  } = props;

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPhoneVisible, setIsPhoneVisible] = useState(false);
  const [displayValue, setDisplayValue] = useState('');

  useEffect(() => {
    if (field.value && isPhone) {
      const rawNumber = field.value
        ? field.value.replace(/^\+1\s*/, '').replace(/\D/g, '')
        : '';
      setDisplayValue(rawNumber);
    } else {
      setDisplayValue(field.value ?? '');
    }
  }, [field.value, isPhone, isPhoneVisible]);

  const handleChange = (e) => {
    if (isPhone) {
      const formattedFieldValue = field.value.replace('+1 ', '');
      const formattedInputValue = e.target.value.replaceAll('-', '');
      const rawValue = (
        formattedInputValue?.length >= 10
          ? formattedInputValue.slice(-10).replace(/\D/g, '')
          : formattedInputValue.length < formattedFieldValue?.length
          ? formattedFieldValue
              .slice(0, formattedInputValue.length)
              .replace(/\D/g, '')
          : formattedFieldValue +
            formattedInputValue[formattedInputValue?.length - 1]
      )?.replaceAll('X', '');
      field.onChange(`+1 ${rawValue}`);
      setDisplayValue(rawValue);
    } else {
      const rawValue = e.target.value;
      field.onChange(rawValue);
      setDisplayValue(rawValue);
    }
  };

  return (
    <>
      {label && (
        <Stack direction={{ xs: 'row', sm: 'column', lg: 'row' }} gap={0.5}>
          <Typography variant="subtitle2">
            {label}
            {!isNotRequired && (
              <span style={{ color: '#d32f2f', paddingLeft: '3px' }}>*</span>
            )}
          </Typography>
          {secondaryLabel}
        </Stack>
      )}
      <CustomTextField
        fullWidth
        {...rest}
        value={
          isPhone
            ? formatPhoneNumber(displayValue, isPhoneVisible)
            : displayValue
        }
        onChange={handleChange}
        sx={{
          '& .MuiInputBase-root': {
            mt: 1,
            ...sx,
          },
        }}
        label={''}
        type={isPasswordVisible || !type ? 'text' : type}
        error={
          (fieldState?.isTouched || formState?.isSubmitted) &&
          Boolean(fieldState?.error)
        }
        helperText={
          (fieldState?.isTouched || formState?.isSubmitted) &&
          fieldState?.error?.message
        }
        onBlur={(e) => {
          field.onBlur();
          onBlur && onBlur(e.target.value);
        }}
        InputProps={{
          sx: { borderRadius: 0 },
          startAdornment: isPhone ? (
            <InputAdornment position="start">
              <img src={UsFlag} alt="flag" height={'20px'} />
              <Typography
                ml={0.5}
                pr={1}
                sx={{
                  borderRightWidth: 1,
                  borderRightColor: 'lightgrey',
                  borderRightStyle: 'solid',
                }}
              >
                +1
              </Typography>
            </InputAdornment>
          ) : null,
          endAdornment:
            type === 'password' ? (
              // || isPhone
              <InputAdornment position="end">
                <IconButton
                  disabled={props.disabled}
                  onClick={() => {
                    if (isPhone) {
                      setIsPhoneVisible((prevState) => !prevState);
                      return;
                    }
                    setIsPasswordVisible((prevState) => !prevState);
                  }}
                >
                  {isPasswordVisible || isPhoneVisible ? (
                    <VisibilityOffIcon />
                  ) : (
                    <RemoveRedEyeIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ) : null,
        }}
      />
    </>
  );
}

HookTextField.propTypes = {
  label: PropTypes.any,
  isNotRequired: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  sx: PropTypes.object,
  secondaryLabel: PropTypes.element,
  onBlur: PropTypes.any,
  isPhone: PropTypes.bool,
};
