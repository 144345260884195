import React, { useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useBlocker } from 'react-router-dom';

import ButtonSx from 'components/button/ButtonSx';
import { pxToRem } from 'theme-loader/theme/typography';

export const useCustomBlocker = ({
  isDirty = false,
  message,
  handleSaveData = () => {},
  showSaveBtn = true,
}) => {
  // check if any unsaved changes exits/ leave to another path
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      isDirty &&
      (currentLocation.pathname !== nextLocation.pathname ||
        currentLocation.search !== nextLocation.search)
    );
  });

  // code to handle the the reload event, if the changes are unsaved
  useEffect(() => {
    function handleBeforeUnload(event) {
      return isDirty ? (event.returnValue = true) : null;
    }
    window.addEventListener('beforeunload', handleBeforeUnload, {
      capture: true,
    });

    return () => {
      window.addEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  const customDialog = (
    <Dialog
      open={blocker.state === 'blocked'}
      PaperProps={{
        sx: {
          width: '600px !important',
        },
      }}
    >
      <DialogTitle>
        <Typography fontSize={pxToRem(18)} fontWeight={500} pt={2}>
          {message ||
            'You have unsaved changes. Are you sure you want to leave this page? Any unsaved data will be lost.'}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Stack
          sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
          justifyContent={'end'}
          gap={3}
        >
          {[
            showSaveBtn && {
              label: 'Save and Leave',
              actions: () => {
                if (handleSaveData) handleSaveData();
                blocker.proceed();
              },
            },
            { label: 'Leave', actions: () => blocker.proceed() },
            { label: 'Stay', actions: () => blocker.reset() },
          ]
            ?.filter(Boolean)
            ?.map((item, index) => (
              <ButtonSx
                key={index}
                sx={{
                  fontWeight: 700,
                  px: { xs: 1, md: 2 },
                  width: { xs: '100%', sm: 200 },
                  float: 'right',
                }}
                buttonText={item?.label}
                variant="light-bg"
                onButtonPress={item?.actions}
              />
            ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );

  return [customDialog];
};
