import React from 'react';

import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

const ToolTipSx = (props) => {
  const { title } = props;
  return (
    <Tooltip
      {...props}
      arrow
      placement="top"
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -20],
              },
            },
          ],
        },
      }}
      title={title}
    >
      {props?.children}
    </Tooltip>
  );
};

ToolTipSx.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
};

export default ToolTipSx;
