import * as React from 'react';

import AddIcon from '@mui/icons-material/Add';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import {
  Box,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import debounce from 'lodash.debounce';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AlertPopup from 'components/alert-popup/AlertPopup';
import CommonGridView from 'components/common/CommonGridView';
import CommonListView from 'components/common/CommonListView';
import CommonToggleViewSection from 'components/common/CommonToggleViewSection';
import useAuth from 'context/AuthContext';
import { useGetCustomerQuery } from 'store/apis/customer';
import {
  useDeleteCabinetMutation,
  useGetCabinetsByCategoryQuery,
  useRestoreCabinetMutation,
} from 'store/apis/order';
import { queryParamsBuilder } from 'utils/commonFunctions';
import { cabinetsOptions } from 'utils/constants';
import { useNotifyToast } from 'utils/useNotifyToast';

import styles from './cabinetDetails.module.css';
import ImportCSV from './common/ImportCsv';

const generalColumns = [
  { field: 'index', label: 'Index', width: 130 },
  { field: 'code', label: 'Code Name', width: 300 },
  { field: 'shortName', label: 'Short Name', width: 320 },
  {
    field: 'longName',
    label: 'Long Name',
    width: 300,
    flex: 1,
    minWidth: 200,
  },
];
export default function CabinetDetails() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const [selectedCabId, setSelectedCabId] = React.useState('');
  const [selectedRestoreCabId, setSelectedRestoreCabId] = React.useState('');

  const [confirmValue, setConfirmValue] = React.useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const cabinetType = searchParams.get('type') || 'base';
  const viewType = searchParams.get('view') || 'list';

  const [searchText, setSearchText] = React.useState('');
  const [isUploadDialogOpen, setIsUploadDialogOpen] = React.useState(false);

  const [deleteCabinet, { isLoading, isSuccess, isError, error }] =
    useDeleteCabinetMutation();

  const [
    restoreCabinet,
    {
      isLoading: isRestoreCabLoading,
      isSuccess: isRestoreCabSuccess,
      isError: isRestoreCabError,
      error: restoreCabError,
    },
  ] = useRestoreCabinetMutation();

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });

  const [sortModel, setSortModel] = React.useState([
    { field: 'index', sort: 'asc' },
  ]);

  // api Calls
  const { data: user } = useGetCustomerQuery(
    {},
    { skip: !auth?.authenticated }
  );

  const {
    data: cabinetData,
    isLoading: isCabinetLoading,
    isFetching: isCabinetFetching,
  } = useGetCabinetsByCategoryQuery({
    ...paginationModel,
    page: paginationModel.page + 1,
    type: cabinetType,
    search: searchText,
    orderBy: sortModel?.[0]?.sort || 'asc',
    viewType,
  });

  React.useEffect(() => {
    if (user && user?.role !== 'ADMIN') {
      navigate('/');
    }
  }, [navigate, user]);

  const columns = React.useMemo(
    () =>
      [
        ...generalColumns.map((col) => ({
          field: col.field,
          headerName: col.label,
          sortable: col.field === 'index',
          width: col.width || undefined,
          flex: col.flex || undefined,
          sortComparator:
            col.field === 'index'
              ? (v1, v2) => Number(v1) - Number(v2)
              : undefined,
          // align: 'center',
          // headerAlign: 'center',
          renderCell: (params) => {
            return (
              <Stack
                direction="row"
                justifyContent="left"
                spacing={2}
                alignItems="center"
                height={'100%'}
                sx={{ pl: 2 }}
              >
                <Tooltip
                  title={`${params?.row?.[col.field]}`}
                  placement="top"
                  arrow
                >
                  <Typography
                    noWrap
                    sx={{
                      // maxWidth: '250px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      width: '100%',
                    }}
                  >
                    {params?.row?.[col.field]}
                  </Typography>
                </Tooltip>
              </Stack>
            );
          },
        })),
        ...[
          {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: ({ id, row }) => {
              if (row?.isDeleted) {
                return (
                  <Tooltip title="Restore cabinet">
                    <IconButton
                      onClick={() => {
                        setSelectedRestoreCabId(id);

                        restoreCabinet(id);
                      }}
                    >
                      <RestoreIcon
                        sx={{ color: (theme) => theme.palette.info.light }}
                      />
                    </IconButton>
                  </Tooltip>
                );
              }
              return (
                <>
                  <Tooltip title="View/Edit Details">
                    <IconButton
                      onClick={() => {
                        navigate(
                          `/cabinets/${id}${queryParamsBuilder({
                            type: cabinetType,
                          })}`
                        );
                      }}
                    >
                      <BorderColorIcon
                        sx={{ color: (theme) => theme.palette.success.light }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Cabinet">
                    <IconButton
                      onClick={() => {
                        setSelectedCabId(id);
                        setIsAlertOpen(true);
                      }}
                    >
                      <DeleteIcon
                        sx={{ color: (theme) => theme.palette.error.dark }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              );
            },
          },
        ],
      ] || [],
    [cabinetType, navigate, restoreCabinet]
  );

  const notifyToast = useNotifyToast();

  React.useEffect(() => {
    if (!isLoading && isSuccess) {
      setSelectedCabId('');
      notifyToast('Cabinet deleted successfully', 'cabinetDelete', 'success');
    } else if (isError && error) {
      notifyToast(
        error?.data?.errorMessage || 'Something went wrong',
        'cabinetDelete',
        'error'
      );
    }
  }, [error, isError, isLoading, isSuccess, notifyToast]);

  React.useEffect(() => {
    if (!isRestoreCabLoading && isRestoreCabSuccess) {
      notifyToast('Cabinet restored successfully', 'cabinetRestore', 'success');
      setSelectedRestoreCabId('');
    } else if (isRestoreCabError && restoreCabError) {
      notifyToast(
        restoreCabError?.data?.errorMessage || 'Something went wrong',
        'cabinetRestore',
        'error'
      );
    }
  }, [
    isRestoreCabError,
    isRestoreCabLoading,
    isRestoreCabSuccess,
    notifyToast,
    restoreCabError,
  ]);

  const handleViewChange = (event, newValue) => {
    setSearchParams({ view: newValue });
  };

  const getRowClassName = (params) => {
    return params.row.isDeleted ? styles.disabledRow : '';
  };

  return (
    <Box sx={{ height: '100%', mx: 2 }}>
      <CommonToggleViewSection
        heading={'Cabinets'}
        selectLabel={'Selected Cabinets'}
        options={cabinetsOptions}
        selectedOption={cabinetType}
        onSelectChange={(e) => {
          setSearchParams({ type: e.target.value, view: viewType });
        }}
        onSearch={debounce((e) => {
          setSearchText(e.target.value);
        }, 300)}
        rightActionBtns={[
          {
            startIcon: <AddIcon />,
            buttonText: 'Add Cabinet',
            onPress: () => {
              navigate(
                `/add-cabinet${queryParamsBuilder({ type: cabinetType })}`
              );
            },
          },
        ]}
        extraOptions={[
          <ImportCSV
            key="import-csv"
            buttonText={
              <Typography sx={{ fontWeight: 'bold' }}>
                Import Cabinets
              </Typography>
            }
            isUploadDialogOpen={isUploadDialogOpen}
            handleClose={() => setIsUploadDialogOpen(false)}
            setIsUploadDialogOpen={setIsUploadDialogOpen}
            cabinetType={cabinetType}
            onImport={(submitFunction, csvData) => {
              submitFunction();
            }}
          />,
        ]}
        tabOptions={[
          {
            label: 'List',
            value: 'list',
            component: (
              <CommonListView
                sortModel={sortModel}
                setSortModel={setSortModel}
                rows={cabinetData?.data || []}
                columns={columns}
                getRowClassName={getRowClassName}
                rowCount={cabinetData?.totalCount || 0}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                isLoading={isCabinetLoading || isCabinetFetching}
                noRowsOverlay={'No Cabinet Found'}
              />
            ),
          },
          {
            label: 'Grid',
            value: 'grid',
            component: (
              <CommonGridView
                data={cabinetData?.data}
                noDataMsg={'No Cabinet Found'}
                type={'cabinet'}
                onDelete={(item) => {
                  setSelectedCabId(item?.id);
                  setIsAlertOpen(true);
                }}
                onRestore={(item) => {
                  setSelectedRestoreCabId(item?.id);
                  restoreCabinet(item?.id);
                }}
                selectedRestoreCabId={selectedRestoreCabId}
                isBtnLoading={isRestoreCabLoading}
                onButtonPress={(item) => {
                  navigate(
                    `/cabinets/${item?.id}${queryParamsBuilder({
                      type: cabinetType,
                    })}`
                  );
                }}
              />
            ),
          },
        ]}
        viewType={viewType}
        onTabChange={handleViewChange}
        isDataLoading={isCabinetLoading || isCabinetFetching}
      />

      <AlertPopup
        title={'Delete Cabinet?'}
        description={
          'Are you sure you want to delete this cabinet? All the cabinet information added will be lost. This action cannot be undone.'
        }
        submitButtonText="Confirm"
        isOpen={isAlertOpen}
        handleClose={() => {
          setIsAlertOpen(false);
          setSelectedCabId('');
          setConfirmValue('');
        }}
        isConfirmLoading={isLoading}
        isConfirmDisabled={confirmValue.toLowerCase() !== 'delete'}
        onConfirm={() => {
          deleteCabinet(selectedCabId);
          setConfirmValue('');
          setIsAlertOpen(false);
        }}
      >
        <Box px={3}>
          <Typography
            fontWeight={'bold'}
            sx={{ color: (theme) => theme.palette.text.secondary }}
          >
            Please type in DELETE to confirm
          </Typography>
          <TextField
            fullWidth
            value={confirmValue}
            onChange={(e) => {
              setConfirmValue(e.target.value);
            }}
            placeholder="Type to confirm"
            id="outlined-basic"
            variant="outlined"
            sx={{
              'mt': 1,
              '& .MuiInputBase-root': {
                height: 50,
                backgroundColor: 'white',
              },
            }}
          />
        </Box>
      </AlertPopup>
    </Box>
  );
}
