import React from 'react';

import { Box, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';

import { datagridSx } from 'pages/orders/Orders';

function CommonListView({
  sortModel,
  setSortModel,
  rows,
  columns,
  getRowClassName,
  rowCount,
  paginationModel,
  setPaginationModel,
  isLoading = false,
  noRowsOverlayText = 'No Data Found',
}) {
  return (
    <Paper
      elevation={0}
      sx={{
        'height': 'auto',

        'width': '99.5%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
        'mb': 10,
        'zIndex': 0,
        'ml': 1.5,
      }}
    >
      <DataGrid
        sortModel={sortModel}
        onSortModelChange={(model) => {
          if (model?.[0]?.sort === 'asc') {
            setSortModel([{ field: 'index', sort: 'desc' }]);
          } else {
            setSortModel(model);
          }
        }}
        sx={datagridSx}
        rows={rows?.map((item) => ({
          ...item,
          index: Number(item?.index),
        }))}
        disableColumnMenu
        columns={columns}
        getRowClassName={getRowClassName}
        paginationMode="server"
        rowCount={rowCount}
        pageSizeOptions={[10, 20, 30, 40, 50, 60, 70]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        loading={isLoading}
        slots={{
          noRowsOverlay: () => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                typography: 'body1',
              }}
            >
              {noRowsOverlayText}
            </Box>
          ),
        }}
      />
    </Paper>
  );
}

CommonListView.propTypes = {
  columns: PropTypes.any,
  datagridSx: PropTypes.any,
  getRowClassName: PropTypes.any,
  isLoading: PropTypes.bool,
  rowCount: PropTypes.any,
  noRowsOverlayText: PropTypes.string,
  paginationModel: PropTypes.any,
  rows: PropTypes.any,
  setPaginationModel: PropTypes.any,
  setSortModel: PropTypes.func,
  sortModel: PropTypes.any,
};

export default CommonListView;
