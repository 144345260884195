import React from 'react';

import { FormHelperText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { queryParamsBuilder } from 'utils/commonFunctions';

const ReactHookFormSelect = (props) => {
  const navigate = useNavigate();
  const labelId = `${props.name}-label`;
  const { helperText, boxShadow, viewAllOption, ...restProps } = props;

  return (
    <>
      {props.label && (
        <Stack direction="row" gap={0.5} justifyContent={'space-between'}>
          <Stack direction={'row'}>
            <Typography gutterBottom variant="subtitle2">
              {props.label}
            </Typography>
            {!props.isNotRequired && <Typography color="error">*</Typography>}
          </Stack>
          {viewAllOption?.status && (
            <Typography
              gutterBottom
              sx={{ fontSize: '12px', color: 'red', cursor: 'pointer' }}
              onClick={() => {
                navigate(
                  `/select-material/${viewAllOption?.navigateTo}${
                    viewAllOption?.from
                      ? queryParamsBuilder({ from: viewAllOption?.from })
                      : ''
                  }`
                );
              }}
            >
              View Catalog
            </Typography>
          )}
        </Stack>
      )}
      <FormControl {...restProps} fullWidth>
        <Controller
          control={props.control}
          render={({ field }) => {
            return (
              <Select
                MenuProps={{
                  style: { maxHeight: '500px' },
                }}
                displayEmpty
                labelId={labelId}
                sx={{ borderRadius: 0, boxShadow: boxShadow ?? 0 }}
                {...field}
                placeholder="Select"
                onChange={(e) => {
                  props.onChange && props.onChange(e.target.value);
                  field.onChange(e);
                }}
                renderValue={(value) => {
                  if (!value?.length) {
                    return (
                      <div>
                        {props.placeholder ? props.placeholder : 'Select'}
                      </div>
                    );
                  }
                  return value;
                }}
              >
                {props.children}
              </Select>
            );
          }}
          name={props.name}
          defaultValue={props.defaultValue}
        />
        {props.error ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    </>
  );
};

ReactHookFormSelect.propTypes = {
  children: PropTypes.any,
  control: PropTypes.any,
  defaultValue: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.any,
  helperText: PropTypes.any,
  error: PropTypes.any,
  onChange: PropTypes.func,
  isNotRequired: PropTypes.bool,
  placeholder: PropTypes.string,
  boxShadow: PropTypes.string,
  viewAllOption: PropTypes.object,
};
export default ReactHookFormSelect;
