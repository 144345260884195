import * as React from 'react';

import { Box, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import AlertPopup from 'components/alert-popup/AlertPopup';

const ProjectPopup = ({
  title,
  desc,
  submitButtonText,
  isOpen,
  onClose = () => {},
  isConfirmLoading,
  isConfirmDisabled,
  onConfirm,
  oProjectName,
  childComponent,
  cancelBtnDisable = false,
  ...props
}) => {
  const [projectName, setProjectName] = React.useState(oProjectName);

  return (
    <AlertPopup
      cancelBtnDisable={cancelBtnDisable}
      title={title || 'Create a project!'}
      description={
        desc ||
        'Assign this item to a project for better organization. Enter a project name to group multiple cabinets together.'
      }
      submitButtonText={submitButtonText || 'Create'}
      isOpen={isOpen}
      handleClose={onClose}
      isConfirmLoading={isConfirmLoading}
      isConfirmDisabled={isConfirmDisabled}
      onConfirm={() => onConfirm(projectName)}
      {...props}
    >
      <Box sx={{ mx: 3 }}>
        {childComponent ? (
          childComponent
        ) : (
          <TextField
            label="Project name"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            sx={{ width: '100%' }}
            inputProps={{ maxLength: 70 }}
          />
        )}
      </Box>
    </AlertPopup>
  );
};
ProjectPopup.propTypes = {
  cancelBtnDisable: PropTypes.any,
  childComponent: PropTypes.any,
  children: PropTypes.node,
  desc: PropTypes.string,
  isConfirmDisabled: PropTypes.any,
  isConfirmLoading: PropTypes.any,
  isOpen: PropTypes.any,
  oProjectName: PropTypes.any,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  submitButtonText: PropTypes.string,
  title: PropTypes.string,
};

export default ProjectPopup;
